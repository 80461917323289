import React, { useEffect, useState } from "react";
import { getData, patchData } from "./api";
import { useTranslation } from "react-i18next";

const Preferences = (props) => {
  const { t, i18n } = useTranslation();

  const [crypto, setCrypto] = useState(true);
  const [fitness, setFitness] = useState(true);
  const [wearables, setWearables] = useState(true);
  const [prefSent, setPrefSent] = useState(false);

  useEffect(() => {
    getData(
      "admin/preferences",
      (res) => {
        setCrypto(res.crypto);
        setFitness(res.fitness);
        setWearables(res.wearables);
      },
      (err) => console.log(err)
    );
  }, []);

  return (
    <div class="container-wrapper bg-offwhite border-top">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6 element-wrapper">
            <h1 class="blue">{t("preferences.title")}</h1>
            <p>
            {t("preferences.para1")}
            </p>
            <h3 class="blue">{t("preferences.label1")}</h3>
            <form class="">
              <div class="form-group">
                <input
                  checked={crypto}
                  onChange={() => {
                    setCrypto(!crypto);
                    setPrefSent(false);
                  }}
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  {" "}
                  {t("preferences.label2")}
                </label>
              </div>
              <div class="form-group">
                <input
                  checked={fitness}
                  onChange={() => {
                    setFitness(!fitness);
                    setPrefSent(false);
                  }}
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  {" "}
                  {t("preferences.label3")}
                </label>
              </div>
              <div class="form-group">
                <input
                  checked={wearables}
                  onChange={() => {
                    setWearables(!wearables);
                    setPrefSent(false);
                  }}
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                />
                <label class="form-check-label" for="defaultCheck1">
                  {" "}
                  {t("preferences.label4")}
                </label>
              </div>
              <button
                onClick={() => {
                  patchData(
                    "admin/preferences",
                    { crypto: crypto, fitness: fitness, wearables: wearables },
                    (res) => console.log(res),
                    (err) => console.log(err)
                  );
                  setPrefSent(true);
                }}
                type="button"
                class="btn btn-primary btn-lg"
              >
                {t("preferences.label5")}
              </button>
            </form>
              {prefSent && (
                <h4 className="blue success-message">{t("preferences.label6")}</h4>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
