import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { getData, deleteData, postData, patchData } from "./api";
import * as linkify from "linkifyjs";
import linkifyHtml from "linkifyjs/html";

const Communications = (props) => {
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [comments, setComments] = useState([]);
  const [viewComm, setViewComm] = useState(null);
  const [addComment, setAddComment] = useState(null);
  const [adminViewComments, setAdminViewComments] = useState(null);
  const [commentSent, setCommentSent] = useState(false);
  const [comment, setComment] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [userId, setUserId] = useState(null);
  const [editComment, setEditComment] = useState(null);
  const [reply, setReply] = useState(null);

  useEffect(() => {
    console.log("Fetching admin");
    getData(
      "admin/info",
      (res) => {
        if (res.userRole === "admin" || res.userRole === "superadmin") {
          setAdmin(true);
        }
        setUserId(res.userID);
      },
      (err) => console.log(err)
    );
  }, []);

  useEffect(() => {
    console.log("Fetching data.");
    getData(
      "communications",
      (res) => {
        setData(res);
      },
      (err) => console.log(err)
    );
  }, [data.length]);

  useEffect(() => {
    console.log("Fetching comments");
    getData(
      "admin/comments",
      (res) => {
        setComments(res);
      },
      (err) => console.log(err)
    );
  }, [comments.length, adminViewComments]);

  var update = [];

  const handleDeleteUpdate = (id) => {
    deleteData(
      "communications/" + id,
      (res) => setData([]),
      (err) => console.log(err)
    );
  };

  const handleDeleteComment = (id) => {
    deleteData(
      "comments/" + id,
      (res) => setComments([]),
      (err) => console.log(err)
    );
  };

  const handleApprove = (id) => {
    patchData(
      "admin/comments/" + id + "/approve",
      {},
      (res) => setComments([]),
      (err) => console.log(err)
    );
  };

  if (viewComm) {
    const date = new Date(viewComm.createdAt);
    return (
      <div>
        <div class="container-wrapper bg-offwhite pb-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 element-wrapper">
                <h1 class="">{viewComm.title}</h1>
                <p className="link" onClick={() => setViewComm(null)}>
                  {t("updates.label10")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="container-wrapper bg-offwhite">
          <div class="container-fluid">
            {viewComm.headings.map((heading, index) => {
              let bodyText;
              let linkFound;
              if (viewComm.bodies[index]) {
                linkFound = viewComm.bodies[index].includes('http')
               if (linkFound) {
                 bodyText = linkifyHtml(viewComm.bodies[index], {
                   defaultProtocol: "https",
                 });
               }
              }

              return (
                <div class="row">
                  <div class="col-12 col-md-8 element-wrapper">
                    <h3 class="blue">{heading}</h3>
                    {linkFound && viewComm.bodies[index] ? <div
                      class="body-text"
                      dangerouslySetInnerHTML={{ __html: bodyText }}
                    ></div> : <p>{viewComm.bodies[index]}</p>}
                  </div>
                  {viewComm.images[index] != undefined && (
                    <p>
                      <img className="img-fluid" src={viewComm.images[index]} />
                    </p>
                  )}
                </div>
              );
            })}
            <div class="comments mt-5">
              <h3 class="blue">{t("updates.label6")}</h3>

              {comments.map((comment) => {
                if (
                  comment.approved &&
                  comment.newsTitle === viewComm.title &&
                  !comment.replyId
                ) {
                  return (
                    <div>
                      <p class="blue detail-text">{comment.content}</p>
                      <p className="small ">
                        {comment.firstName + " " + comment.lastName}
                      </p>
                    </div>
                  );
                }
              })}

              <form class="mt-5 ">
                <div class="form-group white">
                  <label for="exampleFormControlTextarea1" class="blue">
                    {t("news.label4")}
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-lg"
                  onClick={() => {
                    if (comment) {
                      postData(
                        "admin/comments",
                        {
                          replyid: reply ? reply : null,
                          communicationId: viewComm.id,
                          newsTitle: viewComm.title,
                          content: comment,
                        },
                        (res) => {
                          setCommentSent(true);
                        },
                        (err) => console.log(err)
                      );
                      setComment("");
                    }
                  }}
                >
                  {t("news.label5")}
                </button>
                {commentSent && (
                  <h4 className="blue success-message">{t("news.label6")}</h4>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (adminViewComments) {
    return (
      <div class="container-wrapper bg-offwhite border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 element-wrapper">
              <h1 class="blue">{t("updates.label5")}</h1>
              <p
                className="link"
                onClick={() => {
                  setAdminViewComments(false);
                }}
              >
                {t("updates.label10")}
              </p>

              {comments.filter((comm) => !comm.approved).length == 0 && (
                <p>{t("updates.label9")}</p>
              )}

              {comments
                .filter((comm) => comm.communicationId)
                .map((comment, index) => {
                  if (!comment.approved) {
                    const date = new Date(comment.createdAt);
                    var updateLink = "/update?id=" + comment.id;

                    return (
                      <div class="mt-5">
                        <p className="detail-text">{comment.content}</p>
                        <p className="small ">
                          {comment.firstName + " " + comment.lastName}
                        </p>

                        <p>
                          {" "}
                          <button
                            onClick={() => {
                              handleApprove(comment.id);
                            }}
                            type="button"
                            class="btn btn-primary btn-lg"
                          >
                            {t("updates.label7")}
                          </button>{" "}
                          <button
                            onClick={() => {
                              handleDeleteComment(comment.id);
                            }}
                            type="button"
                            class="btn btn-primary btn-lg"
                          >
                            {t("updates.label8")}
                          </button>
                        </p>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div class="container-wrapper bg-offwhite border-top pb-0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 element-wrapper">
              <h1 class="blue">{t("updates.title")}</h1>
              <p>{t("updates.para1")}</p>
              {admin && (
                <form class="form-inline my-2">
                  <Link to="/update/create">
                    <button type="button" class="btn btn-primary btn-lg">
                      {t("updates.label2")}
                    </button>
                  </Link>
                  &nbsp;
                  <button
                    onClick={() => {
                      setAdminViewComments(true);
                    }}
                    type="button"
                    class="btn btn-primary btn-lg"
                  >
                    {t("updates.label5")} (
                    {
                      comments
                        .filter((comm) => comm.communicationId)
                        .filter((comm) => !comm.approved).length
                    }
                    )
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      {!data.length ? (
        <div class="container-wrapper bg-offwhite pb-0  border-top">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 element-wrapper">
                <p>{t("updates.label1")}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {data
            .sort((a, b) => {
              var dateA = new Date(a.createdAt);
              var dateB = new Date(b.createdAt);
              return dateB - dateA;
            })
            .map((comm, index) => {
              const date = new Date(comm.createdAt);
              var updateLink = "/update?id=" + comm.id;

              return (
                <div class="container-wrapper bg-offwhite">
                  <div class="container-fluid bg-blue">
                    <div class="row">
                      <div
                        onClick={() => setViewComm(comm)}
                        class="col-12 col-md-8 element-wrapper "
                      >
                        <h1 class="white">{comm.title}</h1>

                        <p class="white">{date.toLocaleDateString()}</p>
                      </div>
                      <div class="col-12 col-md-4 element-wrapper">
                        <img class="img-fluid" src={comm.images[0]} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}{" "}
        </div>
      )}
    </div>
  );
};

export default Communications;
