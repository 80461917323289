import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import i18next from "./i18n";
import { I18nextProvider } from "react-i18next";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

let history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App history={history} />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
