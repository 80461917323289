import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dwnImg from "./downloads.jpg";

const Downloads = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div class="container-wrapper bg-offwhite pb-0 border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 element-wrapper">
              <h1 class="blue">{t("downloads.title")}</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="container-wrapper bg-offwhite">
        <div class="container-fluid bg-blue">
          <div class="row">
            <div class="col-12 col-md-6 element-wrapper white">
              <h1 class="">{t("downloads.title2")}</h1>
              <p class="">
              {t("downloads.para1")}
              </p>

              <p>
              {t("downloads.para2")}
              </p>

              <p>
              {t("downloads.para3")}
              </p>

              <p>
              {t("downloads.para4")}
              </p>

              <p>
                {t("downloads.para5")}
              </p>
            </div>
            <div class="col-12 col-md-6 element-wrapper">
              <img class="img-fluid" src="img/ISO_Top_image.jpeg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
