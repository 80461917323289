import React, { useEffect, useState } from "react";
import { getData, patchData, getChainOpsRequest, postData } from "./api";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

//address=0x0c32b1aed9b1f8a9f43ee0d98786347b3573ad97

//"https://acs-dev.auth.us-west-2.amazoncognito.com/login?client_id=4ahj876h3lih4gjhmm5571b1ji&response_type=code&scope=phone+email+openid+aws.cognito.signin.user.admin+profile&redirect_uri=https://ardatoken.com/account"

const Account = (props) => {
  const { t, i18n } = useTranslation();

  // const [accountInfo, setAccountInfo] = useState({tokenBalance: 100});
  const [accountInfo, setAccountInfo] = useState(false);
  const [transactions, setTransactions] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [pageNumber, setPageNumber] = useState(12);

  let query = useQuery();

  useEffect(() => {
    if (query.get("code") && !accountInfo) {
      setLoading(true);
      postData(
        "accountInfo",
        { code: query.get("code") },
        (res) => {
          console.log("res", res);
          // console.log('res', res.response.body);
          let jsonRes = JSON.parse(res);
          setAccountInfo(jsonRes);
          if (jsonRes.address) {
            fetch(
              `https://api.etherscan.io/api?module=account&action=tokentx&address=${jsonRes.address}&startblock=0&endblock=99999999&sort=asc&apikey=43R4P3WT9BMSXS4ATKAABIDT648Y679KMC`
            )
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                setTransactions(
                  data.result.filter((result) => result.tokenSymbol == "ARDAT")
                );
              });
          }
          setLoading(false);
        },
        (err) => {
          console.log("err", err);
          setLoading(false);
        }
      );
    }
  }, []);

  if (!query.get("code")) {
    return (
      <div class="container-wrapper bg-offwhite border-top">
        <div class="container-fluid element-wrapper">
          <div class="row">
            <div class="col-12 col-md-6">
              <h1 class="blue">Account</h1>
              <p>View all your account details and transaction history below</p>
              <hr />
              <p>
                We take your security seriously, so in order to view your
                account information we require you to pass a second layer of
                authentication provided by AWS Cognito. Click the button below
                to proceed.
              </p>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  window.location.assign(
                    "https://acs-prod.auth.us-west-2.amazoncognito.com/login?client_id=7tbht1s757afip7to3fm717h2j&response_type=code&scope=profile+aws.cognito.signin.user.admin+openid+email+phone&redirect_uri=https://ardatoken.com/account"
                  );
                }}
              >
                Authenticate
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div class="container-wrapper bg-offwhite border-top">
      <div class="container-fluid element-wrapper">
        <div class="row">
          <div class="col-12 col-md-6">
            <h1 class="blue">Account</h1>
            <p>View all your account details and transaction history below</p>
            <hr />
            <h3 class="blue">Account Balance</h3>
            {loading ? (
              <Spinner animation="border"></Spinner>
            ) : accountInfo == null ? (
              <p>
                ARDAT Balance currently unavailable. A Wallet has not been
                created.
              </p>
            ) : accountInfo.tokenBalance > -1 ? (
              <p>You currently have {accountInfo.tokenBalance} ARDATs.</p>
            ) : (
              <p>ARDAT Balance currently unavailable.</p>
            )}
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-auto col-md-auto">
            <h3 class="blue">Transaction History</h3>
            <div class="table-responsive">
              {loading ? (
                <Spinner animation="border"></Spinner>
              ) : transactions.length ? (
                <table class="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Date/Time</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Value (ARDATs)</th>
                      <th>Transaction Cost (Ether)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((trans) => {
                      console.log(parseInt(trans.timeStamp));
                      return (
                        <tr>
                          <td class="small">
                            {new Date(
                              parseInt(trans.timeStamp + "000")
                            ).toUTCString()}
                          </td>
                          <td class="small">{trans.from}</td>
                          <td class="small">{trans.to}</td>
                          <td class="small">
                            {trans.value.slice(0, trans.value.length - 18) +
                              "." +
                              trans.value.slice(-18)}
                          </td>
                          <td class="small">
                            {(trans.gasPrice / 1000000000 / 1000000000) *
                              trans.gasUsed}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>
                  ARDAT Transaction History currently unavailable. You may not
                  have made any transactions yet.
                </p>
              )}
              {/* <ul class="pagination">
                <li class="page-item">
                  <button class="btn btn-primary btn-lg page-item">Prev</button>
                </li>
                <li class="page-item">
                  <h3>{pageNumber}</h3>
                </li>
                <li class="page-item">
                  <button class="btn btn-primary btn-lg page-item">Next</button>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
