import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("lang", event.target.value);
  };

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      var userLang = navigator.language || navigator.userLanguage;
      if (userLang == "ja") {
        i18n.changeLanguage("jp");
      }
    } else {
      i18n.changeLanguage(localStorage.getItem("lang"));
    }
  }, []);

  return (
    <div className="footer container-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 element-wrapper">
            <p>
              {t("footer.label1")}
              <br />
              <a href="https://ardatoken.com/ARDATPortal-TermsofService.pdf">
                {t("footer.label2")}
              </a>
              <br />
              <a href="https://ardatoken.com/PrivacyPolicy-ARDATPortal.pdf">
                {t("footer.label3")}
              </a>
            </p>

            <div class="form-group">
              <label for="sel1">Language:</label>
              <div class="col-6">
                <select
                  onChange={changeLanguage}
                  class="form-control form-control-sm"
                  id="sel1"
                  value={localStorage.getItem("lang")}
                  defaultValue="en"
                >
                  <option  value="en">
                    English
                  </option>
                  <option value="jp">Japanese</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="footer">
  //     <div className="company-container">
  //       <div className="footer-header">{t("footer.label1")}</div>
  //       <a className="link" href="https://ardatoken.com/ARDATPortal-TermsofService.pdf">
  //         {t("footer.label2")}
  //       </a>
  //       <a className="link" href="https://ardatoken.com/PrivacyPolicy-ARDATPortal.pdf">
  //         {t("footer.label3")}
  //       </a>
  //       {/* <div onChange={changeLanguage}>
  //         <input type="radio" value="en" name="language" defaultChecked />{" "}
  //         English
  //         <input type="radio" value="jp" name="language" />
  //         Japanese
  //       </div> */}
  //     </div>
  //   </div>
  // );
};

export default Footer;
