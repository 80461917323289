import React, { useState, useEffect, useRef } from "react";

import { useHistory } from "react-router";
import { Alert, AlertTitle } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";

import {
  login,
  register,
  verifyEmail,
  requestForgotPassword,
  requestResetPassword,
} from "./api";

const Login = (props) => {
  const { t, i18n } = useTranslation();

  const [password, setPsw] = useState("");
  const [verifyPassword, setVerifyPsw] = useState("");
  const [email, setEmail] = useState("");

  const [forgotPassword, setPasswordForgotten] = useState(false);
  const [forgotPasswordSuccess, setPasswordForgottenSuccess] = useState(false);

  const [error, setError] = useState(false);

  const [verifyToken, setVerifyToken] = useState("");
  const [verifyEmailSuccess, setVerifyEmailSuccess] = useState(false);

  const [resetToken, setResetToken] = useState("");
  const [resetPasswordSuccess, setPasswordResetSuccess] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");

  const [registerForm, setRegisterForm] = useState(false);
  const [registerFormSent, setRegisterFormSent] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (history.location.search.includes("token")) {
      if (history.location.search.includes("vtoken")) {
        let token = history.location.search.split("=").pop();
        setVerifyToken(token);
      } else {
        let token = history.location.search.split("=").pop();
        setResetToken(token);
      }
    }

    if (props.loggedIn) {
      history.push("/");
      console.log("Logged in going back to home.");
      // props.setPage("home");
    }
  }, [props, history]);

  useEffect(() => {
    if (verifyToken.length) {
      requestVerifyEmail();
    }
  }, [verifyToken]);

  useEffect(() => {
    if (forgotPassword && error) {
      setError(false);
    }

    if (forgotPasswordSuccess && error) {
      setError(false);
    }

    if (resetPasswordSuccess && error) {
      setError(false);
    }
  }, [forgotPassword, forgotPasswordSuccess, resetPasswordSuccess, error]);

  const requestLogin = () => {
    login({ email, password }, handleLogin, handleErr);
  };

  const requestRegister = () => {
    if (password === verifyPassword) {
      register(
        { firstName, lastName, email, password, phone, mobile },
        handleRegister,
        handleErr
      );
    } else {
      setError({
        error: "",
        error_description: "Passwords do not match",
      });
    }
  };

  const handleLogin = (res) => {
    if (res.status === 200) {
      props.setHasToken(true);
      props.setLogin(true);
      history.push("/news");
    }
  };

  const handleRegister = (res) => {
    if (res.status === 201) {
      resetState();
      setRegisterFormSent(true);
    }
  };

  const handleErr = (err) => {
    setError(err);
  };

  const requestVerifyEmail = () => {
    verifyEmail({ verifyToken }, handleVerifyEmail, handleErr);
  };

  const handleVerifyEmail = (res) => {
    if (res.status === 204) {
      setVerifyEmailSuccess(true);
    }
  };

  const handleForgottenPassword = () => {
    requestForgotPassword(
      email,
      (r) => setPasswordForgottenSuccess(true),
      passwordForgottenError
    );
  };

  const passwordForgottenError = (error) => {
    setPasswordForgottenSuccess(false);
    setError(error);
  };

  const handleResetPassword = () => {
    if (password === verifyPassword) {
      let creds = {
        resetToken: resetToken,
        email: email,
        password: password,
      };

      requestResetPassword(
        creds,
        (r) => {
          setPasswordResetSuccess(true);

          history.push("/login");
        },
        passwordResetError
      );
    } else {
      setError({
        error: "",
        error_description: "Passwords do not match",
      });
    }
  };

  const passwordResetError = (error) => {
    setPasswordResetSuccess(false);
    setError(error);
  };

  const resetState = () => {
    setPsw("");
    setVerifyPsw("");
    setEmail("");
    setPhone("");
    setFirstName("");
    setLastName("");
    setMobile("");

    setPasswordForgotten(false);
    setPasswordForgottenSuccess(false);

    setVerifyToken(false);
    setVerifyEmailSuccess(false);

    setError(false);

    setResetToken(false);
    setPasswordResetSuccess(false);
  };

  //Allows user to press enter to send login form

  // const handleKeyPress = (event) => {
  //   if (event.code === "Enter" || event.code === "NumpadEnter") {
  //     if (registerForm) {
  //       requestRegister();
  //     } else {
  //       requestLogin();
  //     }
  //   }
  // };

  if (registerForm) {
    return (
      <div class="container-wrapper bg-offwhite border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 element-wrapper">
              <h1 class="blue">{t("login.label6")}</h1>
              <p
                className="link"
                onClick={() => {
                  setRegisterForm(false);
                }}
              >
                {t("login.label24")}
              </p>
              <form class="mt-5">
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label1")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder={t("login.label1")}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label17")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder={t("login.label17")}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label18")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder={t("login.label18")}
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label19")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder={t("login.label19")}
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label20")}</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder={t("login.label20")}
                    onChange={(e) => setMobile(e.target.value)}
                    value={mobile}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label2")}</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder={t("login.label2")}
                    onChange={(e) => setPsw(e.target.value)}
                    value={password}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label21")}</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder={t("login.label21")}
                    onChange={(e) => setVerifyPsw(e.target.value)}
                    value={verifyPassword}
                  />
                </div>

                <button
                  onClick={requestRegister}
                  type="button"
                  class="btn btn-primary btn-lg"
                >
                  {t("login.label6")}
                </button>
              </form>
              {registerFormSent && (
                <div>
                  <h4 className="blue success-message">{t("login.label23")}</h4>
                  <p
                    className="link"
                    onClick={() => {
                      setRegisterFormSent(false);
                      setRegisterForm(false);
                    }}
                  >
                    {t("login.label24")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (resetToken.length) {
    return (
      <div class="container-wrapper bg-offwhite border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 element-wrapper">
              <h1 class="blue">{t("login.label11")}</h1>
              <form class="mt-5">
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label1")}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label2")}</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    onChange={(e) => setPsw(e.target.value)}
                  />
                </div>
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label21")}</label>
                  <input
                    type="password"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    onChange={(e) => setVerifyPsw(e.target.value)}
                  />
                </div>

                <button
                  type="button"
                  onClick={handleResetPassword}
                  class="btn btn-primary btn-lg"
                >
                  {t("login.label25")}
                </button>
              </form>
              {resetPasswordSuccess && (
                <div>
                  <h4 className="blue success-message">
                  {t("login.label16")}
                  </h4>

                  <p className="link" onClick={() => setResetToken("")}>
                  {t("login.label24")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (verifyToken.length) {
    return (
      <div class="container-wrapper bg-offwhite border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 element-wrapper">
              <h1 class="blue">{t("login.label26")}</h1>
              <p
                className="link"
                onClick={() => {
                  setVerifyToken(false);
                  setVerifyEmailSuccess(false);
                }}
              >
                {t("login.label24")}
              </p>
              {verifyEmailSuccess ? (
                <h4 className="blue success-message">{t("login.label14")}</h4>
              ) : (
                <h4 className="blue success-message">{t("login.label15")}</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (forgotPassword) {
    return (
      <div class="container-wrapper bg-offwhite border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 element-wrapper">
              <h1 class="blue">Forgot Password</h1>
              <p className="link" onClick={() => setPasswordForgotten(false)}>
              {t("login.label24")}
              </p>
              <form class="mt-5">
                <div class="form-group ">
                  <label for="exampleFormControlInput1">{t("login.label1")}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="button"
                  onClick={handleForgottenPassword}
                  class="btn btn-primary btn-lg"
                >
                  {t("login.label27")}
                </button>
              </form>
              {forgotPasswordSuccess && (
                <h4 className="blue success-message">{t("login.label28")}</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="container-wrapper bg-blue ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-6 element-wrapper">
              <h1 className="white">{t("login.title2")}</h1>
              <h4 className="white">
              {t("login.para1")}
              </h4>

              <p className="white">
              {t("login.para2")}
              </p>
              <p>
                <form className="mt-5">
                  <div className="form-group white">
                    <label for="exampleFormControlInput1">{t("login.label1")}</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group white">
                    <label for="exampleFormControlInput1">{t("login.label2")}</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder=""
                      onChange={(e) => setPsw(e.target.value)}
                    />
                  </div>
                  <p>
                    <button
                      type="button"
                      onClick={() => {
                        if (email != "" && password != "") {
                          requestLogin();
                        }
                      }}
                      className="btn btn-primary btn-lg"
                    >
                      {t("login.label4")}
                    </button>
                  </p>
                  {error && (
                    <p className="red success-message">{error.error_description}</p>
                  )}
                  <p
                    onClick={() => setPasswordForgotten(true)}
                    className="small link"
                  >
                    {t("login.label3")}
                  </p>
                  <p class="small white">
                  {t("login.label5")}{" "}
                    <div
                      onClick={() => {
                        setRegisterForm(true);
                      }}
                      className="link"
                    >
                      {t("login.label6")}
                    </div>
                  </p>
                </form>
              </p>
            </div>
            <div className="col-12 col-md-6 element-wrapper">
              <img
                className="img-fluid"
                src="img/malik-skydsgaard-jLWV1Vr8wfM-unsplash.jpeg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-wrapper bg-lightblue">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-8 element-wrapper">
              <h2 className="blue">{t("login.title3")}</h2>
              <p className="blue">
              {t("login.para3")}
              </p>

              <p className="blue">
              {t("login.para4")}{" "}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 element-wrapper">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/55QpkJLxN8c"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;