import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getData, patchData, postData, deleteData } from "./api";

const News = (props) => {
  const { t, i18n } = useTranslation();

  const cryptoUrl = "https://api.ardat.nz/v1/rss?feed=crypto";
  const fitnessUrl = "https://api.ardat.nz/v1/rss?feed=fitness";
  const wearableUrl = "https://api.ardat.nz/v1/rss?feed=wearables";
  const [data, setData] = useState([]);
  const [viewComm, setViewComm] = useState(null);
  const [articles, setArticles] = useState([]);
  const [preferences, setPreferences] = useState({
    crypto: false,
    fitness: false,
    wearables: false,
  });
  const [addComment, setAddComment] = useState(null);
  const [comment, setComment] = useState(null);
  const [editComment, setEditComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentSent, setCommentSent] = useState(false);
  const [userId, setUserId] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [adminViewComments, setAdminViewComments] = useState(null);
  const [reply, setReply] = useState(null);

  const handleDeleteComment = (id) => {
    deleteData(
      "comments/" + id,
      (res) => setComments([]),
      (err) => console.log(err)
    );
  };

  const handleApprove = (id) => {
    patchData(
      "admin/comments/" + id + "/approve",
      {},
      (res) => setComments([]),
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    console.log("Fetching admin");
    getData(
      "admin/info",
      (res) => {
        if (res.userRole === "admin" || res.userRole === "superadmin") {
          setAdmin(true);
        }
        setUserId(res.userID);
      },
      (err) => console.log(err)
    );
  }, []);

  useEffect(() => {
    console.log("Fetching comments");
    getData(
      "admin/comments",
      (res) => {
        setComments(res);
      },
      (err) => console.log(err)
    );
  }, [comments.length, adminViewComments]);

  useEffect(() => {
    console.log("Fetching preferences");
    getData(
      "admin/preferences",
      (res) => {
        console.log("res", res);
        setPreferences({
          crypto: res.crypto,
          fitness: res.fitness,
          wearables: res.wearables,
        });
      },
      (err) => console.log(err)
    );
  }, []);

  useEffect(() => {
    console.log("Fetching feeds");
    if (preferences.wearables) {
      console.log("Getting wearables feed");
      fetch(wearableUrl)
        .then((response) => response.text())
        .then((str) =>
          new window.DOMParser().parseFromString(str, "application/xml")
        )
        .then((data) => {
          console.log(data);
          const items = data.querySelectorAll("item");
          var newArticlesWear = [];
          items.forEach((item) => {
            var article = {};
            article.bodies = [];
            article.headings = [];
            article.images = [];
            article.subtitle = "Wearables - Wareable.Com";
            article.createdAt = item.querySelector("pubDate").innerHTML;
            article.title = item
              .querySelector("title")
              .innerHTML.replace("<![CDATA[", "")
              .replace("]]>", "")
              .replaceAll("&amp;", " ");
            article.headings.push(
              item
                .querySelector("description")
                .innerHTML.replace("<![CDATA[", "")
                .replace("]]>", "")
                .split("</p>")[0]
                .replace("<p>", "")
                .replaceAll("&nbsp;", " ")
                .replaceAll("&#8230;", " ")
            );
            if (item.querySelector("link")) {
              article.bodies.push(item.querySelector("link").innerHTML);
            }
            if (item.querySelector("enclosure")) {
              article.images.push(
                item.querySelector("enclosure").getAttribute("url")
              );
            }
            newArticlesWear.push(article);
          });

          setArticles((articles) =>
            [...articles, ...newArticlesWear].sort(
              (a, b) => Date.parse(a.createdAt) + Date.parse(b.createdAt)
            )
          );
        });
    }
    if (preferences.crypto) {
      console.log("Getting crypto feed");
      fetch(cryptoUrl)
        .then((response) => response.text())
        .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          const items = data.querySelectorAll("item");
          var newArticles = [];
          items.forEach((item) => {
            var article = {};
            article.bodies = [];
            article.headings = [];
            article.images = [];
            article.subtitle = "Crypto - CoinTelegraph";
            article.createdAt = item.querySelector("pubDate").innerHTML;
            article.title = item
              .querySelector("title")
              .innerHTML.replace("<![CDATA[", "")
              .replace("]]>", "");
            article.headings.push(
              item
                .querySelector("description")
                .innerHTML.replace("<![CDATA[", "")
                .replace("]]>", "")
                .split("<p>")[1]
                .replace("</p>", "")
            );
            article.bodies.push(item.querySelector("link").innerHTML);
            article.images.push(
              item.querySelector("enclosure").getAttribute("url")
            );
            newArticles.push(article);
          });

          setArticles((articles) =>
            [...articles, ...newArticles].sort(
              (a, b) => Date.parse(a.createdAt) + Date.parse(b.createdAt)
            )
          );
        });
    }

    // if (preferences.fitness) {
    //   console.log("Getting fitness feed");
    //   fetch(fitnessUrl)
    //     .then((response) => response.text())
    //     .then((str) =>
    //       new window.DOMParser().parseFromString(str, "application/xml")
    //     )
    //     .then((data) => {
    //       console.log(data);
    //       const items = data.querySelectorAll("item");
    //       var newArticles = [];
    //       items.forEach((item) => {
    //         var article = {};
    //         article.bodies = [];
    //         article.headings = [];
    //         article.images = [];
    //         article.subtitle = "Fitness - Jillian Michaels";
    //         article.createdAt = item.querySelector("pubDate").innerHTML;
    //         article.title = item
    //           .querySelector("title")
    //           .innerHTML.replace("<![CDATA[", "")
    //           .replace("]]>", "")
    //           .replaceAll("&amp;", " ");
    //         article.headings.push(
    //           item
    //             .querySelector("description")
    //             .innerHTML.replace("<![CDATA[", "")
    //             .replace("]]>", "")
    //             .split("</p>")[0]
    //             .replace("<p>", "")
    //             .replaceAll("&nbsp;", " ")
    //         );
    //         if (item.querySelector("link")) {
    //           article.bodies.push(item.querySelector("link").innerHTML);
    //         }
    //         if (item.querySelector("enclosure")) {
    //           article.images.push(
    //             item.querySelector("enclosure").getAttribute("url")
    //           );
    //         }
    //         newArticles.push(article);
    //       });

    //       setArticles((articles) => [...articles, ...newArticles]);
    //     });
    // }

    // setArticles((articles) =>
    //   articles.sort((a, b) => Date.parse(a.createdAt) + Date.parse(b.createdAt))
    // );
  }, [preferences]);

  if (viewComm) {
    console.log(viewComm);
    const date = new Date(viewComm.createdAt);
    return (
      <div>
        <div class="container-wrapper bg-offwhite pb-0 border-top">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 element-wrapper">
                <h1 class="blue">{viewComm.title}</h1>
                <p className="link" onClick={() => setViewComm(null)}>
                {t("news.label2")}
                </p>
              </div>
            </div>
          </div>
        </div>

        {viewComm.headings.map((heading, index) => {
          return (
            <div class="container-wrapper bg-offwhite">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 col-md-8 element-wrapper">
                    <p class="blue">{heading}</p>

                    <p>
                      <a href={viewComm.bodies[index]}>
                        {viewComm.bodies[index]}
                      </a>
                    </p>

                    {viewComm.images && viewComm.images.length && (
                      <p>
                        {viewComm.images.length >= 1 && (
                          <img src={viewComm.images[0]} />
                        )}
                      </p>
                    )}
                    <div class="comments mt-5">
                      <h3 class="blue">{t("news.label3")}</h3>

                      {comments.map((comment) => {
                        if (
                          comment.approved &&
                          comment.newsTitle === viewComm.title &&
                          !comment.replyId
                        ) {
                          return (
                            <div>
                              <p class="blue detail-text">{comment.content}</p>
                              <p className="small ">{comment.firstName + " " + comment.lastName}</p>
                            </div>
                          );
                        }
                      })}

                      <form class="mt-5 ">
                        <div class="form-group white">
                          <label for="exampleFormControlTextarea1" class="blue">
                          {t("news.label4")}
                          </label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></textarea>
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary btn-lg"
                          onClick={() => {
                            if (comment) {
                              postData(
                                "admin/comments",
                                {
                                  replyid: reply ? reply : null,
                                  communicationId: null,
                                  newsTitle: viewComm.title,
                                  content: comment,
                                },
                                (res) => {setCommentSent(true)},
                                (err) => console.log(err)
                              );
                              setComment("");
                            }
                          }}
                        >
                          {t("news.label5")}
                        </button>
                        {commentSent && <h4 className="blue success-message">{t("news.label6")}</h4>}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  if (adminViewComments) {
    return (
      <div class="container-wrapper bg-offwhite border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 element-wrapper">
              <h1 class="blue">{t("news.label7")}</h1>
              <p
                class="link"
                onClick={() => {
                  setAdminViewComments(false);
                }}
              >
                {t("news.label2")}
              </p>

              {comments.filter((comm) => !comm.approved).length == 0 && (
                <p>{t("updates.label9")}</p>
              )}

              {comments
                .filter((comm) => !comm.communicationId)
                .map((comment, index) => {
                  if (!comment.approved) {
                    const date = new Date(comment.createdAt);
                    var updateLink = "/update?id=" + comment.id;

                    return (
                      <div class="mt-5">
                        <p className="detail-text">{comment.content}</p>
                        <p className="small ">{comment.firstName + " " + comment.lastName}</p>
                        <p>
                          {" "}
                          <button
                            onClick={() => {
                              handleApprove(comment.id);
                            }}
                            type="button"
                            class="btn btn-primary btn-lg"
                          >
                            {t("updates.label7")}
                          </button>{" "}
                          <button
                            onClick={() => {
                              handleDeleteComment(comment.id);
                            }}
                            type="button"
                            class="btn btn-primary btn-lg"
                          >
                            {t("updates.label8")}
                          </button>
                        </p>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="main-container">
      <div class="container-wrapper bg-offwhite pb-0  border-top">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 element-wrapper">
              <h1 class="blue">{t("news.title")}</h1>
              <p>
              {t("news.para1")}
              </p>
              {admin && <form class="form-inline my-2">
                <button
                  type="button"
                  onClick={() => {
                    setAdminViewComments(true);
                  }}
                  class="btn btn-primary btn-lg"
                >
                  {t("news.label7")} (
                  {
                    comments
                      .filter((comm) => !comm.communicationId)
                      .filter((comm) => !comm.approved).length
                  }
                  )
                </button>
              </form>}
            </div>
          </div>
        </div>
      </div>
      {!articles.length ? (
        <div class="container-wrapper bg-offwhite pb-0  border-top">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 element-wrapper">
                <p>{t("news.label1")}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {articles.length > 1 &&
            articles
              .filter((article, index) => {
                return articles.indexOf(article) === index;
              })
              .sort((a, b) => Date.parse(a.createdAt) + Date.parse(b.createdAt))
              .slice(0, 5)
              .map((comm) => {
                const date = new Date(comm.createdAt);
                return (
                  <div class="container-wrapper bg-offwhite">
                    <div class="container-fluid bg-blue">
                      <div class="row">
                        <div
                          onClick={() => setViewComm(comm)}
                          class="col-12 col-md-8 element-wrapper "
                        >
                          <h1 class="white">{comm.title}</h1>

                          <h4 class="white">{comm.subtitle}</h4>

                          <p class="white">{date.toLocaleDateString()}</p>
                        </div>
                        <div class="col-12 col-md-4 element-wrapper">
                          <img class="img-fluid" src={comm.images[0]} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      )}
    </div>
  );
};

export default News;
