import React, { useEffect, useState } from "react";
import { getData, patchData } from "./api";
import { useTranslation } from "react-i18next";

const Admin = (props) => {
  const { t, i18n } = useTranslation();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [privSent, setPrivSent] = useState(false);

  useEffect(() => {
    getData(
      "admin/users",
      (res) => {
        setUsers(res);
      },
      (err) => console.log(err)
    );
  }, []);

  return (
    <div className="main-container">
      <div className="main-page-header">Admin Settings</div>
      <div className="login-container">
        <div className="login-form-container">
          <div className="login-info">Grant Admin Priviledges</div>
          <select className="admin-dropdown" onChange={(event) => {setSelectedUser(event.target.value)}}>
            {users.map((user) => {
              return (
                <option value={user.id}>{user.firstName + " " + user.lastName + " - " + user.email}</option>
              );
            })}
          </select>
          <button
            className="preference-form-button"
            onClick={() => {
              patchData(
                "admin/users/" + selectedUser + "/upgrade",
                {},
                (res) => console.log(res),
                (err) => console.log(err)
              );
              setPrivSent(true);
            }}
          >
            Submit
          </button>
          {privSent && <div className="priv-success">Priviledges Granted!</div>}
        </div>
      </div>
    </div>
  );
};

export default Admin;
