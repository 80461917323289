import request from 'superagent'

// const baseUrl = 'http://127.0.0.1:1323';
const baseUrl = 'https://api.ardat.nz';

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function login(creds, callback, error) {
  let cid = uuidv4();
  request
    .post(`${baseUrl}/auth/token`)
    .send({grant_type: 'password', ...creds})
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('X-Request-ID', cid)
    .then(res => {
      switch (res.status) {
        case 200: {
          localStorage.setItem('token', res.body.token)
          localStorage.setItem('user', creds.email)
          localStorage.setItem('expiresat', res.body.expiresAt)
          callback(res)
          break;
        }
        case 401: {
          logout()
          error && error(res.response.body)
          break;
        }
        default: return false
      }
    })
    .catch(err => {
      err = JSON.parse(JSON.stringify(err))
      console.log("Err: ", err)
      error && error(err.response.body)
    })
}

export function register(user, callback, error) {
  let cid = uuidv4();
  request
    .post(`${baseUrl}/v1/users/register`)
    .send(user)
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('X-Request-ID', cid)
    .then(res => {
      switch (res.status) {
        case 200: {
          callback(res)
          break;
        }
        case 201: {
          callback(res)
          break;
        }
        case 202: {
          callback(res)
          break;
        }
        case 401: {
          error && error(res.response.body)
          break;
        }
        case 400: {
          error && error(res.response.body)
          break;
        }
        default: return false
      }
    })
    .catch(err => {
      err = JSON.parse(JSON.stringify(err))
      console.log("Err: ", err)
      error && error(err.response.body)
    })
}

export function verifyEmail(token, callback, error) {
  let cid = uuidv4();
  request
    .patch(`${baseUrl}/v1/users/verifyemail`)
    .send(token)
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('X-Request-ID', cid)
    .then(res => {
      switch (res.status) {
        case 200: {
          callback(res)
          break;
        }
        case 201: {
          callback(res)
          break;
        }
        case 204: {
          callback(res)
          break;
        }
        case 401: {
          error && error(res.response.body)
          break;
        }
        case 400: {
          error && error(res.response.body)
          break;
        }
        default: return false
      }
    })
    .catch(err => {
      err = JSON.parse(JSON.stringify(err))
      console.log("Err: ", err)
      error && error(err.response.body)
    })
}

export function requestForgotPassword(email, callback, error) {
  let cid = uuidv4();
  request
    .get(`${baseUrl}/v1/users/forgotpassword/${email}`)
    // .send({grant_type: 'password', ...creds})
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('X-Request-ID', cid)
    .then(res => {
      switch (res.status) {
        case 200: {
          callback(res)
          break;
        }
        case 202: {
          callback(res)
          break;
        }
        case 204: {
          callback(res)
          break;
        }
        case 401: {
          error && error(res.response.body)
          break;
        }
        case 400: {
          error && error(res.response.body)
          break;
        }
        default: return false
      }
    })
    .catch(err => {
      err = JSON.parse(JSON.stringify(err))
      error && error(err.response.body)
    })
}

export function requestResetPassword(creds, callback, error) {
  let cid = uuidv4();
  request
    .patch(`${baseUrl}/v1/users/resetpassword`)
    .send(creds)
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('X-Request-ID', cid)
    .then(res => {
      switch (res.status) {
        case 200: {
          callback(res)
          break;
        }
        case 202: {
          callback(res)
          break;
        }
        case 204: {
          callback(res)
          break;
        }
        case 401: {
          error && error(res.response.body)
          break;
        }
        case 500: {
          error && error(res.response.body)
          break;
        }
        default: return false
      }
    })
    .catch(err => {
      err = JSON.parse(JSON.stringify(err))
      error && error(err.response.body)
    })
}

export function logout() {
  localStorage.removeItem('token')
  localStorage.removeItem('expiresat')
  localStorage.removeItem('user')
}

export function getData(url, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem('token')
  request
  .get(`${baseUrl}/v1/${url}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${token}`)
  .set('Access-Control-Expose-Headers', '*')
  .set('X-Request-ID', cid)
  .then(res => {
    switch (res.status) {
      case 200: {
        // TO DO: send response headers - get count
        if (!res.body){
          callback(res.text)
        } else {
          callback(res.body, res.header['x-total-count'])
        }
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      case 404: {
        error && error(res.body)
        break;
      }
      case 401: {
        logout()
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}

export function deleteData(url, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem('token')
  request
  .delete(`${baseUrl}/v1/admin/${url}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Authorization', `Bearer ${token}`)
  .set('Access-Control-Allow-Origin', '*')
  .set('X-Request-ID', cid)
  .then(res => {
    switch (res.status) {
      case 204: {
        callback(res.body)
        break;
      }
      case 201: {
        callback(res.body)
        break;
      }
      case 401: {
        logout()
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}

export function postData(url, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem('token')
  request
  .post(`${baseUrl}/v1/${url}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Authorization', `Bearer ${token}`)
  .set('Access-Control-Allow-Origin', '*')
  .set('X-Request-ID', cid)
  .send(data)
  .then(res => {
    switch (res.status) {
      case 200: {
        if (!res.body) {
          callback(res.text)
        } else {
          callback(res.body)
        }
        break;
      }
      case 201: {
        callback(res.body)
        break;
      }
      case 204: {
        callback(res.body)
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      case 401: {
        error && error(res.body)
        break;
      }
        // case 401: {
        //   logout()
        //   break;
        // }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}

export function getChainOpsRequest(callback, error) {
  let cid = uuidv4();
  const token = 'eyJraWQiOiI0NjJkYkdVZGpSS1h0c085c0ladHFHMStsM1pUckN2azUrNlZEMmF3SzBFPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiS01qajJNR1AyYTE0Q0xwdmd3ZXk5dyIsInN1YiI6ImIwMTQyMjcwLTFhM2MtNGRiYS04M2ZiLWE2Mjk5MjQyNzA4ZCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY3VzdG9tOndlaWdodCI6IjIwIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfdjBVSnNsR3RlIiwiY29nbml0bzp1c2VybmFtZSI6ImIwMTQyMjcwLTFhM2MtNGRiYS04M2ZiLWE2Mjk5MjQyNzA4ZCIsImF1ZCI6IjFuZ29paTRnYnBnb2lzcGg4Y2tkdDFkMTB0IiwiZXZlbnRfaWQiOiJlMzZmNzUwMi1jMDI0LTQ0ZTgtYmQ0Yi02NTdkOWM1MDZmYWUiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYyMjUxMjE0OCwiY3VzdG9tOnByZWZlcnJlZE5hbWUiOiJGYXRpbWEiLCJleHAiOjE2MjI1MTU3NDgsImlhdCI6MTYyMjUxMjE0OCwiZW1haWwiOiJmYXRpbWFAcGVyZm9ybWFuY2VsYWIuY28ubnoifQ.kiO1_Eciaxq5y12NjGEQfZmt65BuPI4dDUCW_MM8iyG1iK3AT3b05A9JxtuI17q79o_BzOwKmH2hY6H6L_b0Mp7gcCwuPEIrlC46fp-_nDWYey9ITFLedp-lB9MQqjiigG0NBTRM631Q9SCTm3YVstG1BmoZuUz4iFwBNKfDsRLZM22Ds5QUd7ogzJnKRhqSF3vufbZ3P9mO4jGfb9-5p9pqD6Akdf6Z_65CQ7Vm3s2Z3OwfuFbnB1lnDO5bMOQdbWw0cjDDzSpU3MhJTvS9Jkut9G7wpnuyhDwXjB_vmNsfDuH5bZXRMZ2O3lIR11g96BCHZLasmjOsv6xzWWZUpw'
  request
  .get(`https://acs.dev.ardacoaching.com/api/athletes/blockchain-config/`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Authorization', `Bearer ${token}`)
  .set('Access-Control-Allow-Origin', '*')
  .set('X-Request-ID', cid)
  .then(res => {
    switch (res.status) {
      case 200: {
        callback(res.body)
        break;
      }
      case 201: {
        callback(res.body)
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      case 401: {
        logout()
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}

export function patchData(url, data, callback, error, ) {
  let cid = uuidv4();
  const token = localStorage.getItem('token')
  request
  .patch(`${baseUrl}/v1/${url}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${token}`)
  .set('X-Request-ID', cid)
  .send(data)
  .then(res => {
    switch (res.status) {
      case 204: {
        callback(res.body)
        break;
      }
      case 201: {
        callback(res.body)
        break;
      }
      case 401: {
        logout()
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}

// Activate / Deactive user
export function patchUserStatus(id, status, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem('token')
  request
  .patch(`${baseUrl}/v1/admin/users/${id}/${status}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${token}`)
  .set('X-Request-ID', cid)
  // .send(data)
  .then(res => {
    switch (res.status) {
      case 204: {
        callback(res.body)
        break;
      }
      case 201: {
        callback(res.body)
        break;
      }
      case 401: {
        logout()
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}

export function search(type, isAdmin, query, props, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem('token')
  request
  .get(`${baseUrl}/v1/${isAdmin ? 'admin/' : ''}${type}?query=${query}${props}`)
  .set('Accept', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Content-Type', 'application/json')
  .set('Authorization', `Bearer ${token}`)
  .set('X-Request-ID', cid)
  // .send(data)
  .then(res => {
    switch (res.status) {
      case 200: {
        callback(res.body, res.header['x-total-count'])
        break;
      }
      case 401: {
        logout()
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    error && error(null)
    err = JSON.parse(JSON.stringify(err))
    // error && error(err)
  })
}

export function getPageContent(where, callback, error) {
  let cid = uuidv4();
  request
  .get(`${baseUrl}/v1/contents/${where}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('X-Request-ID', cid)
  .then(res => {
    switch (res.status) {
      case 200: {
        callback(res.body)
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      case 422: {
        error && error(res.body)
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}

export function updatePageContent(id, data, callback, error) {
  let cid = uuidv4();
  const token = localStorage.getItem('token')
  request
  .patch(`${baseUrl}/v1/admin/contents/${id}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('Authorization', `Bearer ${token}`)
  .set('X-Request-ID', cid)
  .send(data)
  .then(res => {
    switch (res.status) {
      case 200: {
        callback(res.body)
        break;
      }
      case 204: {
        callback(res.body)
        break;
      }
      case 403: {
        error && error(res.reponse.body)
        break;
      }
      case 422: {
        error && error(res.response.body)
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err.response.body)
  })
}

export function sendEmail(data, emailWhere, callback, error) {
  let cid = uuidv4();
  request
  .post(`${baseUrl}/v1/${emailWhere}`)
  .set('Accept', 'application/json')
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*')
  .set('X-Request-ID', cid)
  .send(data)
  .then(res => {
    switch (res.status) {
      case 204: {
        callback(res.body)
        break;
      }
      case 429: {
        callback(res.body)
        break;
      }
      case 403: {
        error && error(res.body)
        break;
      }
      case 422: {
        error && error(res.body)
        break;
      }
      default: return false
    }
  })
  .catch(err => {
    err = JSON.parse(JSON.stringify(err))
    error && error(err)
  })
}
