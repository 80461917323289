import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { logout, getData } from "./api";

const Header = (props) => {
  const { t, i18n } = useTranslation();
  let location = useLocation();

  console.log("location", location.pathname);

  // const [admin, setAdmin] = useState(false);

  const handleLogout = () => {
    props.setLogin(false);
    logout();
  };

  // useEffect(() => {
  //   getData(
  //     "admin/info",
  //     (res) => {
  //       if (res.userRole === "superadmin") {
  //         setAdmin(true);
  //       }
  //     },
  //     (err) => console.log(err)
  //   );
  // }, [props.loggedIn]);

  return (
    <div className="container-wrapper pt-0 pb-0">
      <nav className="navbar element-wrapper navbar-light navbar-expand-lg pt-4 pb-4">
        <a className="navbar-brand" href="/">
          <img className="logo" src="https://ardatoken.com/arda.png" />
        </a>

        {props.loggedIn && (
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        )}

        {props.loggedIn && (
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <Link className="nav-link" to={"/login"}>
                  {t("header.label1")}{" "}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={!props.loggedIn ? "/login" : "/downloads"}
                >
                  {t("header.label2")}{" "}
                  <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={!props.loggedIn ? "/login" : "/news"}
                >
                  {t("header.label3")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={!props.loggedIn ? "/login" : "/communications"}
                >
                  {t("header.label4")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={!props.loggedIn ? "/login" : "/preferences"}
                >
                  {t("header.label5")}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to={!props.loggedIn ? "/login" : "/account"}
                >
                  Account
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={!props.loggedIn ? "/login" : "/account"}
                >
                  Account
                </Link>
              </li>
              <li className="nav-item">
                <div className="nav-link" onClick={handleLogout}>
                  {t("header.label7")}
                </div>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};
export default Header;
