import React, { useState, useEffect, Suspense } from "react";
import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import News from "./News";
import Downloads from "./Downloads";
import Communications from "./Communications";
import Login from "./Login";
import Preferences from "./Preferences";
import NewUpdate from "./NewUpdate";
import Admin from "./Admin";
import Account from "./Account";


import "./css/style.css";
import "./css/type.css";

import { logout } from "./api.js";
import { Route, Switch, useHistory } from "react-router";

function App() {
  const history = useHistory();
  // let routePage = /[^/]*$/.exec(history.location.pathname)[0]
  // console.log('routePage: ', routePage)
  const [hasToken, setHasToken] = useState(
    localStorage.getItem("token") && true
  );
  const [loggedIn, setLogin] = useState(hasToken);
  // const [page, setPage] = useState(routePage === 'account' ? 'home' : routePage)

  useEffect(() => {
    if ((history.location.pathname !== "/login" || history.location.pathname !== "/") && hasToken) {
      const expiry = localStorage.getItem("expiresat");
      const curTime = Math.floor(Date.now() / 1000);
      if (expiry) {
        if (curTime >= expiry) {
          setLogin(false);
          logout();
          // setPage('login')
          history.push("/login");
        } else {
          // setLogin(true)
        }
      }
    }
    if (!history.location.search.includes("vtoken")) {
      if (!loggedIn && !history.location.pathname.includes("resetpassword")) {
        history.push("/login");
      }
    }
  }, [loggedIn, hasToken, history]);

  return (
    <div className="App">
      <Suspense fallback={null}>
        <div id="wrap" className="mainwrap">
          <Route
            path="/*"
            component={() => (
              <Header
                loggedIn={loggedIn}
                setLogin={(isLoggedIn) => setLogin(isLoggedIn)}
              />
            )}
          />
          <Switch>
            {/* Home screen setting goes in here (was Home component) */}
            <Route exact path="/" component={() => (
              <Login
                setLogin={(isLoggedIn) => setLogin(isLoggedIn)}
                setHasToken={(hasToken) => setHasToken(hasToken)}
                // setPage={(page) => setPage(page)}
                // loggedIn={loggedIn}
              />
            )} />
          </Switch>
          <Route path="/news" component={News} />
          <Route path="/downloads" component={Downloads} />
          <Route path="/communications" component={Communications} />
          <Route path="/preferences" component={Preferences} />
          <Route path="/admin" component={Admin} />
          <Route path="/account" component={Account} />
          <Route
            path="/login"
            component={() => (
              <Login
                setLogin={(isLoggedIn) => setLogin(isLoggedIn)}
                setHasToken={(hasToken) => setHasToken(hasToken)}
                // setPage={(page) => setPage(page)}
                // loggedIn={loggedIn}
              />
            )}
          />
          <Route path="/update" component={NewUpdate} />
          <Route path="/*" component={Footer} />
        </div>
      </Suspense>
    </div>
  );
}

export default App;
