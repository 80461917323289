import React, { useEffect, useState } from "react";
import { postData, getData, patchData } from "./api";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const NewUpdate = (props) => {
  const { t, i18n } = useTranslation();

  const apiUrl = "http://localhost:1323/communications";

  const [updateCreated, setUpdateCreated] = useState(false);

  let query = useQuery();
  let id = query.get("id");

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [headings, setHeadings] = useState([]);
  const [bodies, setBodies] = useState([]);
  const [images, setImages] = useState([]);

  const [headingsCount, setHeadingsCount] = useState(1);
  const [bodiesCount, setBodiesCount] = useState(1);
  const [imagesCount, setImagesCount] = useState(1);

  useEffect(() => {
    if (id) {
      getData(
        "communications/" + id,
        (res) => {
          console.log("res", res);
          setName(res.name);
          setTitle(res.title);
          setSubtitle(res.subtitle);
          setHeadings(res.headings);
          setHeadingsCount(res.headings.length);
          setBodies(res.bodies);
          setBodiesCount(res.bodies.length);
          setImages(res.images);
          setImagesCount(res.images.length);
        },
        (err) => console.log(err)
      );
    }
  }, []);

  const resetState = () => {
    setName("");
    setTitle("");
    setSubtitle("");

    setHeadings([""]);
    setBodies([""]);

    setImages([""]);

    setHeadingsCount(1);
    setBodiesCount(1);
    setImagesCount(1);
  };

  var headingsInputs = [];
  var bodiesInputs = [];
  var imagesInputs = [];

  for (let i = 0; i < headingsCount; i++) {
    var addHeadings;
    if (i == 0) {
      if (headingsCount > 1) {
        addHeadings = (
          <div>
            <button
              className="btn btn-primary btn-create"
              type="button"
              onClick={() => setHeadingsCount((count) => count + 1)}
            >
              +
            </button>
            <button
              className="btn btn-primary btn-create"
              type="button"
              onClick={() => {
                setHeadings((headings) => headings.slice(0, -1));
                setHeadingsCount((count) => count - 1);
              }}
            >
              -
            </button>
          </div>
        );
      } else {
        addHeadings = (
          <button
            className="btn btn-primary btn-create"
            type="button"
            onClick={() => setHeadingsCount((count) => count + 1)}
          >
            +
          </button>
        );
      }
    } else {
      addHeadings = null;
    }
    headingsInputs.push(
      <div className="form-group">
        <label>{t("newUpdate.label1")} {i + 1}</label>

        {addHeadings}
        <input
          className="form-control"
          onChange={(e) => {
            let newHeadings = headings.slice();
            newHeadings[i] = e.target.value;
            setHeadings(newHeadings);
          }}
          type="text"
          value={headings[i]}
        ></input>
      </div>
    );
  }

  for (let i = 0; i < bodiesCount; i++) {
    var addBodies;
    if (i == 0) {
      if (bodiesCount > 1) {
        addBodies = (
          <div>
            <button
              className="btn btn-primary btn-create"
              type="button"
              onClick={() => setBodiesCount((count) => count + 1)}
            >
              +
            </button>
            <button
              className="btn btn-primary btn-create"
              type="button"
              onClick={() => {
                setBodies((bodies) => bodies.slice(0, -1));
                setBodiesCount((count) => count - 1);
              }}
            >
              -
            </button>
          </div>
        );
      } else {
        addBodies = (
          <button
            className="btn btn-primary btn-create"
            type="button"
            onClick={() => setBodiesCount((count) => count + 1)}
          >
            +
          </button>
        );
      }
    } else {
      addBodies = null;
    }
    bodiesInputs.push(
      <div className="form-group">
        <label>{t("newUpdate.label2")} {i + 1}</label>
        {addBodies}
        <input
          className="form-control"
          onChange={(e) => {
            let newBodies = bodies.slice();
            newBodies[i] = e.target.value;
            setBodies(newBodies);
          }}
          type="text"
          value={bodies[i]}
        ></input>
      </div>
    );
  }

  for (let i = 0; i < imagesCount; i++) {
    var addImages;
    if (i == 0) {
      if (imagesCount > 1) {
        addImages = (
          <div>
            <button
              className="btn btn-primary btn-create"
              type="button"
              onClick={() => setImagesCount((count) => count + 1)}
            >
              +
            </button>
            <button
              className="btn btn-primary btn-create"
              type="button"
              onClick={() => {
                setImages((images) => images.slice(0, -1));
                setImagesCount((count) => count - 1);
              }}
            >
              -
            </button>
          </div>
        );
      } else {
        addImages = (
          <button
            className="btn btn-primary btn-create"
            type="button"
            onClick={() => setImagesCount((count) => count + 1)}
          >
            +
          </button>
        );
      }
    } else {
      addImages = null;
    }
    imagesInputs.push(
      <div className="form-group">
        <label>{t("newUpdate.label3")} {i + 1}</label>
        {addImages}
        <input
          className="form-control"
          onChange={(e) => {
            let newImages = images.slice();
            newImages[i] = e.target.value;
            setImages(newImages);
          }}
          type="text"
          value={images[i]}
        ></input>
      </div>
    );
  }

  return (
    <div class="container-wrapper bg-offwhite">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6 element-wrapper">
            <h1 class="blue">{t("newUpdate.title")}</h1>
            <Link className="link" to="/communications">
            {t("newUpdate.label4")}
            </Link>

            <form class="mt-5">
              <div class="form-group ">
                <label for="exampleFormControlInput1">{t("newUpdate.label5")}</label>
                <input
                  class="form-control"
                  placeholder=""
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  value={name}
                />
              </div>
              <div class="form-group ">
                <label for="exampleFormControlInput1">{t("newUpdate.label6")}</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder=""
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  value={title}
                />
              </div>
              <div class="form-group ">
                <label for="exampleFormControlInput1">{t("newUpdate.label7")}</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder=""
                  onChange={(e) => setSubtitle(e.target.value)}
                  type="text"
                  value={subtitle}
                />
              </div>
              {headingsInputs}
              {bodiesInputs}
              {imagesInputs}
              <button
                onClick={() => {
                  if (id) {
                    patchData(
                      "admin/communications/" + id,
                      {
                        name: name,
                        images: images,
                        title: title,
                        subtitle: subtitle,
                        headings: headings,
                        bodies: bodies,
                        // type: type,
                      },
                      (res) => {
                        setUpdateCreated(true);
                        resetState();
                      },
                      (err) => console.log(err)
                    );
                  } else {
                    postData(
                      "admin/communications",
                      {
                        name: name,
                        images: images,
                        title: title,
                        subtitle: subtitle,
                        headings: headings,
                        bodies: bodies,
                        // type: type,
                      },
                      (res) => {
                        setUpdateCreated(true);
                        resetState();
                      },
                      (err) => console.log(err)
                    );
                  }
                }}
                type="button"
                class="btn btn-primary btn-lg"
              >
                {id ? "Update" : "Create"}
              </button>
              {updateCreated && <h4 className="blue success-message">{t("newUpdate.label8")}</h4>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUpdate;
